<template>
  <div>
    <div class="imgWrapper" v-show="modalImg" @click="modalImg = undefined">
      <p class="imgWrapperText">loading image</p>
      <b-img class="imgWrapperImage" fluid-grow :src="`./imgs/${modalImg}`" alt="" />
      <div class="imgWrapperIcon">
        <b-icon class="imgWrapperIcon" icon="x" />
      </div>
    </div>
    <div class="hero">
      <h1>VS Code Plugins</h1>
      <p>I use VS code for coding every day. To help me being more productive I use a lot of plugins.</p>
      <p>Scroll down to explore a selection of my favourites.</p>
      <p>
        <router-link id="back" title="◄ go back" to="/">◄ back</router-link>
      </p>
    </div>
    
    <div class="project" v-for="p in plugins" :key="p.title">
      <h1 :id="p.title">{{p.title}}<a :href="`#${p.title}`" id="hyperlink"><b-icon icon="link45deg" class="icon"/></a></h1>
      <span>author: {{p.author}}</span><br>
      <span>links: 
        [<a :href="p.urls.vsmarketplace" id="hyperlink" target="_blank">VS Code Marketplace</a>]
        [<a :href="p.urls.github" id="hyperlink" target="_blank">GitHub</a>]
        [<a :href="p.urls.install" id="hyperlink" target="_blank">Install</a>]
      </span><br><br>
      <span>{{p.description}}</span><br>
    </div>

  </div>
</template>

<script>
  import plugins from '@/assets/texts/plugins';
  export default {
    name: 'Projects',
    data() {
      return {
        modalImg: '',
        plugins,
      };
    },
    methods: {
      showImg(img) {
        this.modalImg = img;
        // this.$bvModal.show('imgModal')
      },
    }
  }
</script>

<style scoped>

p {
  color: #3b3b3b;
  font-size: 300%;
  margin: 0;
}

  .hero {
    height: 100vh;
  }

  .project {
    margin-bottom: 40rem;
  }

  .imgWrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(10, 10, 10, 0.5);
    padding: 5rem;
  }

  .imgWrapperText {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .imgWrapperImage {
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 20;
    padding: 15rem;
  }

  .imgWrapperIcon {
    z-index: 100;
    height: 2rem;
    width: 2rem;
    color: black;
    background-color: white;
    border-radius: 0.2rem;
    position: fixed;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  .imgWrapperIcon:hover {
    color: white;
    background-color: black;
  }

  .icon {
    margin-left: 1rem;
    font-size: 16pt;
  }
</style>